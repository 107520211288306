import * as React from 'react'

import { AuthRegister } from 'shared/pages'

import Layout from '../../layouts/landing'

const SITE_NAME = process.env.GATSBY_SITE_NAME

const Register = () => (
  <Layout>
    <AuthRegister
      title={`Regístrate en ${SITE_NAME}`}
      description={`Regístrate y comienza a usar ${SITE_NAME}.`}
      allowBusiness
    />
  </Layout>
)

export default Register
